import { handleActions } from 'redux-actions'
import Actions from 'measurements/actions'
import { init, locations } from 'workspace/reducers'
import navigation from 'containers/Navigation/reducer'

import { combineReducers } from 'redux'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const measurements = handleActions(
  {
    [Actions.RECEIVE_MEASUREMENTS]: (state, { payload }) => payload
  },
  []
)

const actions = handleActions(
  {
    [Actions.OK_CLICKED]: (state, { payload: okId }) => ({ ...state, okId }),
    [Actions.NOK_CLICKED]: (state, { payload: nokId }) => ({ ...state, nokId }),
    [Actions.OK_CLOSE_CLICKED]: (state) => ({ ...state, okId: null }),
    [Actions.NOK_CLOSE_CLICKED]: (state) => ({ ...state, nokId: null }),
    [Actions.STATUS_SENT]: () => ({ okId: null, nokId: null })
  },
  { okId: null, nokId: null }
)

export default combineReducers({
  init,
  config,
  locations,
  measurements,
  actions,
  navigation
})
