import { select, call, put } from 'redux-saga/effects'
import api from 'lib/api/client'
import { selectToken } from 'workspace/selectors'
import { receiveMeasurements, statusSent } from 'measurements/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'

export function * loadMeasurements () {
  const token = yield select(selectToken)
  const locationIds = yield select(state => state.locations.selectedIds)
  const { from, to } = yield select(selectNavigationRange)

  const data = yield call(api.get, '/measurements', { token, params: { locationIds, from, to } })

  yield put(receiveMeasurements(data))
}

export function * markAsOk ({ payload: id }) {
  const token = yield select(selectToken)

  yield call(api.post, `/measurements/${id}/ok`, {}, { token })
  yield put(statusSent())

  yield call(loadMeasurements)
}

export function * markAsNok ({ payload: { id, description } }) {
  const token = yield select(selectToken)

  yield call(api.post, `/measurements/${id}/nok`, { description }, { token })
  yield put(statusSent())

  yield call(loadMeasurements)
}
