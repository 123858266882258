import { call, fork, takeLatest, takeLeading } from 'redux-saga/effects'
import heartbeat from 'lib/saga/heartbeat'
import init from './init'
import Actions from 'measurements/actions'
import WorkspaceActions from 'workspace/actions'
import navigation from './navigation'
import navigationInit from './navigationInit'
import { SET_NAVIGATION } from 'containers/Navigation/actions'
import { loadMeasurements, markAsOk, markAsNok } from './measurements'
import reloadMeasurements from './reload'

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Measurements')
  }

  yield call(navigationInit)
  yield fork(reloadMeasurements)
  yield call(init)
  yield call(loadMeasurements)

  yield takeLatest([
    WorkspaceActions.SELECT_LOCATION,
    WorkspaceActions.UNSELECT_LOCATION,
    WorkspaceActions.SHOW_ALL_LOCATIONS,
    WorkspaceActions.HIDE_ALL_LOCATIONS,
    SET_NAVIGATION
  ], navigation)

  yield takeLeading(Actions.OK_CONFIRM_CLICKED, markAsOk)
  yield takeLeading(Actions.NOK_CONFIRM_CLICKED, markAsNok)
}
