import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { DATETIME_FORMAT } from 'lib/const'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import { okClicked, nokClicked } from './actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'

const MeasurementsTableRow = ({
  id,
  startedAt,
  finishedAt,
  location,
  project,
  status,
  reviewer,
  reviewedAt,
  description,
  confirmator,
  confirmedAt,
  onOkClick,
  onNokClick,
  intl
}) => (
  <tr>
    <td>{moment(startedAt).format(DATETIME_FORMAT)}</td>
    <td>{moment(finishedAt).format(DATETIME_FORMAT)}</td>
    <td>{location}</td>
    <td>{project}</td>
    <td>
      {status === 'ok' && intl.formatMessage({ id: 'measurements.status.ok' })}
      {status === 'no_ok' && intl.formatMessage({ id: 'measurements.status.nok' })}
      {!status && intl.formatMessage({ id: 'measurements.status.none' })}
    </td>
    <td>{reviewer && reviewedAt && `${reviewer} (${moment(reviewedAt).format(DATETIME_FORMAT)})`}</td>
    <td>{description}</td>
    <td>{confirmator && confirmedAt && `${confirmator} (${moment(confirmedAt).format(DATETIME_FORMAT)})`}</td>
    <td>
      {!reviewer && (
        <ButtonGroup tableActions>
          <Button color='success' size='small' onClick={onOkClick}>
            { intl.formatMessage({ id: 'measurements.status.ok' }) }
          </Button>
          <Button color='danger' size='small' onClick={onNokClick}>
            { intl.formatMessage({ id: 'measurements.status.nok' }) }
          </Button>
        </ButtonGroup>
      )}
    </td>
  </tr>
)

MeasurementsTableRow.propTypes = {
  id: PropTypes.number,
  startedAt: PropTypes.string,
  finishedAt: PropTypes.string,
  location: PropTypes.string,
  project: PropTypes.string,
  status: PropTypes.string,
  reviewer: PropTypes.string,
  reviewedAt: PropTypes.string,
  description: PropTypes.string,
  confirmator: PropTypes.string,
  confirmedAt: PropTypes.string,
  onOkClick: PropTypes.func,
  onNokClick: PropTypes.func,
  intl: intlShape
}

const mapDispatchToProps = (dispatch, { id }) => {
  return {
    onOkClick: () => dispatch(okClicked(id)),
    onNokClick: () => dispatch(nokClicked(id))
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  injectIntl
)(MeasurementsTableRow)
