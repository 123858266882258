import { call, race, take, delay } from 'redux-saga/effects'
import Actions from 'measurements/actions'
import { loadMeasurements } from './measurements'

const INTERVAL = 60 * 1000

export default function * reloadMeasurements () {
  let result
  yield take(Actions.RECEIVE_MEASUREMENTS)

  while (true) {
    result = yield race({
      action: take(Actions.RECEIVE_MEASUREMENTS),
      timeout: delay(INTERVAL)
    })

    if (result.timeout) {
      yield call(loadMeasurements)
    }
  }
}
