import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { intlShape, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import { okCloseClicked, okConfirmClicked } from './actions'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'

const MeasurementsOkModal = ({ id, onConfirm, onClose, intl }) => {
  if (!id) {
    return null
  }

  return (
    <Modal className='label-modal' onClose={onClose}>
      <div className='label-modal__line'>
        <span style={{ fontSize: '0.65em' }}>
          {intl.formatMessage({ id: 'ays' })}
        </span>
      </div>
      <ButtonGroup>
        <Button color='success' onClick={() => onConfirm(id)}>{intl.formatMessage({ id: 'yes' })}</Button>
        <Button color='danger' onClick={onClose}>{intl.formatMessage({ id: 'no' })}</Button>
      </ButtonGroup>
    </Modal>
  )
}

MeasurementsOkModal.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = (state) => {
  return {
    id: state.actions.okId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(okCloseClicked()),
    onConfirm: (id) => dispatch(okConfirmClicked(id))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MeasurementsOkModal)
