import moment from 'moment-timezone'
import { setNavigationScope, setNavigationDate, configureNavigation } from 'containers/Navigation/actions'
import { DAY, WEEK, TYPE_WEEK_DAY_SHIFT } from 'containers/Navigation/const'
import { put, select } from 'redux-saga/effects'
import { selectTimezone } from 'workspace/selectors'

const getInitialDate = (timezone) => {
  return moment.tz({ hour: 0 }, timezone)
}

function * navigationInit (action) {
  const timezone = yield select(selectTimezone)

  yield put(configureNavigation(TYPE_WEEK_DAY_SHIFT))
  yield put(setNavigationScope(WEEK))
  yield put(setNavigationDate(getInitialDate(timezone)))
}

export default navigationInit
