import { all, put, call, select } from 'redux-saga/effects'
import {
  startInit,
  finishInit
} from 'measurements/actions'
import { selectToken } from 'workspace/selectors'
import api from 'lib/api/client'
import { receiveLocations } from 'workspace/actions'

function * init () {
  yield put(startInit())
  const token = yield select(selectToken)

  const [locations] = yield all([
    call(api.get, '/locations', { token })
  ])

  yield all([
    put(receiveLocations(locations))
  ])

  yield put(finishInit())
}

export default init
