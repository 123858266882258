import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import WorkspaceLocations from 'workspace/WorkspaceLocations'
import WorkspaceNavigation from 'workspace/WorkspaceNavigation'
import MeasurementsTable from './MeasurementsTable'
import MeasurementsOkModal from './MeasurementsOkModal'
import MeasurementsNokModal from './MeasurementsNokModal'

const Measurements = ({ isLoaded }) => {
  if (!isLoaded) {
    return <Loader full />
  }

  return (
    <React.Fragment>
      <div className='workspace__submenu'>
        <div className='workspace__locations'>
          <WorkspaceLocations multiple allowAll />
        </div>
        <div className='workspace__navigation'>
          <WorkspaceNavigation />
        </div>
      </div>

      <div className='timeline-group'>
        <MeasurementsTable />
      </div>

      <MeasurementsOkModal />
      <MeasurementsNokModal />
    </React.Fragment>
  )
}

Measurements.propTypes = {
  isLoaded: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(Measurements)
