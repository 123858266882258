import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { intlShape, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import { nokCloseClicked, nokConfirmClicked } from './actions'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import Input from 'components/Input'

class MeasurementsNokModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      description: ''
    }
  }

  setDescription = (e) => {
    this.setState({ description: e.target.value })
  }

  onConfirmClick = () => {
    if (!this.state.description) {
      return
    }

    this.props.onConfirm(this.props.id, this.state.description)
    this.setState({ description: '' })
  }

  preventSubmit = (e) => {
    e.preventDefault()
  }

  onCloseModal = () => {
    this.setState({ description: '' })
    this.props.onClose()
  }

  render () {
    const { id, intl } = this.props
    const { description } = this.state

    if (!id) {
      return null
    }

    return (
      <Modal className='label-modal' onClose={this.onCloseModal}>
        <div className='label-modal__line'>
          <span style={{ fontSize: '0.65em' }}>
            {intl.formatMessage({ id: 'ays' })}
          </span>
        </div>
        <form className='workspace-form' onSubmit={this.preventSubmit}>
          <Input nested label={intl.formatMessage({ id: 'measurements.modal.description' })} className='workspace-form__control'>
            <input onChange={this.setDescription} value={description} className='workspace-form__input' />
          </Input>
        </form>
        <ButtonGroup>
          <Button
            disabled={!this.state.description}
            color='success'
            onClick={this.onConfirmClick}
          >
            {intl.formatMessage({ id: 'yes' })}
          </Button>
          <Button color='danger' onClick={this.onCloseModal}>{intl.formatMessage({ id: 'no' })}</Button>
        </ButtonGroup>
      </Modal>
    )
  }
}

MeasurementsNokModal.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = (state) => {
  return {
    id: state.actions.nokId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(nokCloseClicked()),
    onConfirm: (id, description) => dispatch(nokConfirmClicked({ id, description }))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MeasurementsNokModal)
