import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import MeasurementsTableRow from './MeasurementsTableRow'

const MeasurementsTable = ({ realizations }) => (
  <div style={{ position: 'relative' }}>
    <table className='table'>
      <thead>
        <tr>
          <th><FormattedMessage id='measurements.table.startedAt' /></th>
          <th><FormattedMessage id='measurements.table.finishedAt' /></th>
          <th><FormattedMessage id='measurements.table.location' /></th>
          <th><FormattedMessage id='measurements.table.project' /></th>
          <th><FormattedMessage id='measurements.table.status' /></th>
          <th><FormattedMessage id='measurements.table.reviewer' /></th>
          <th><FormattedMessage id='measurements.table.description' /></th>
          <th><FormattedMessage id='measurements.table.confirmator' /></th>
          <th className='wmin' />
        </tr>
      </thead>
      <tbody>
        {realizations.map(realization => {
          const measurement = realization.measurement

          return (
            <MeasurementsTableRow
              id={realization.id}
              key={realization.id}
              startedAt={realization.startedAt}
              finishedAt={realization.finishedAt}
              location={realization.location}
              project={realization.project}
              status={measurement && measurement.status}
              reviewer={measurement && measurement.reviewer}
              reviewedAt={measurement && measurement.reviewedAt}
              description={measurement && measurement.description}
              confirmator={measurement && measurement.confirmator}
              confirmedAt={measurement && measurement.confirmedAt}
            />
          )
        })}
      </tbody>
    </table>
  </div>
)

MeasurementsTable.propTypes = {
  realizations: PropTypes.array
}

const mapStateToProps = (state) => ({
  realizations: state.measurements
})

export default connect(mapStateToProps)(MeasurementsTable)
